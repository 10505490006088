import React from 'react';
import StackGrid from "react-stack-grid";
import MaskedInput from 'antd-mask-input'
import { Select, Row, Col, Empty, Typography, Space, Card, Button, Modal, Divider, Form, Collapse, Checkbox } from 'antd';
import Icon from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { MdOutlinePayment } from "react-icons/md";
import { CgFileDocument } from "react-icons/cg";
import { FiRotateCw, FiChevronsUp, FiChevronsDown } from "react-icons/fi";
import { BsInfoCircle, BsExclamationLg } from "react-icons/bs";
import {PullToRefresh} from "react-js-pull-to-refresh";

import SVG from "react-inlinesvg";
import doctor from "../../img/icons/svg/doctor.svg";
import { auto } from '@popperjs/core';

const { Panel } = Collapse;
const { Option, OptGroup } = Select;
const { Text, Title } = Typography;

const Global = props => {

    const [form1] = Form.useForm();

    function titleblock(n) {
        switch(n) {
            case 'created': return <Text className="bold" type="warning">Отправлено</Text>;
            case 'unpaid': return <Text className="bold" type="danger">Ожидается оплата</Text>;
            case 'pending': return <Text className="bold" type="warning">Ожидается оплата</Text>;
            case 'waiting_for_capture': return <Text className="bold" type="warning">Подтверждается</Text>;
            case 'succeeded': return <Text className="bold" type="success">Оплачено</Text>;
            case 'done': return <Text className="bold" type="success">Состоялась</Text>;
            case 'canceled': return <Text className="bold" type="danger">Отменён</Text>;
            default: return <Text className="bold" type="secondary">Неизвестно</Text>;
        }
    }

    async function consult_add(v) {
        v['method'] = 'consult_add';
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        await props.api(v,true);
      }

    //* Вызов окна добавления
    async function consult_push_add(e) {
        await props.setState( { consactive:e } );
        await props.modal('consult_add');
    }

    async function consult_type(type) {
        await props.setState( { constype:type } );

        let method = 'consult_services';
        switch(type) {
            case 1: method = 'consult_services'; break;
            case 2: method = 'consult_active'; break;
            case 3: method = 'consult'; break;
        }
        await props.api({ 'method':method },false);
        return true;
    }

    async function pay(id) {
        props.modal('consult_paylink');

        var v = {};
        v['payid'] = id;
        v['method'] = 'consult_pay';
        await props.api(v,false);
    }

    function price_con(val) {
        return val.replace(/[^\d]/g, '').replace(/\B(?=(?:\d{3})+(?!\d))/g, ' ');
    }

    function onRefresh() {
        return consult_type(props.state.constype);
    }

    return (
        <React.Fragment>
            <Modal mask={false} className="modal-mini bg_chester" key="m0" title="Оплата" visible={props.state.modal === 'consult_paylink'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-body">
                    {!props.state.moreload ?
                        <React.Fragment>
                            {props.state.linktopay !== '' ? (
                                <div>
                                    <div className="fcenter" style={{marginBottom:'10px'}}>
                                        <CgFileDocument style={{fontSize:'100px',color:'#9FC7DE'}} />
                                    </div>
                                    <div className="fcenter">
                                        {
                                            props.state.linktopay === 'nourl' ?
                                                <Button className="button_normal button_red" target="_blank"><span className='button_icon'><MdOutlinePayment /> Временно недоступно</span></Button>
                                            :
                                                <Button className="button_normal button_red" href={props.state.linktopay} target="_blank"><span className='button_icon'><MdOutlinePayment /> Перейти к оплате</span></Button>
                                        }
                                    </div>
                                </div>
                            ) : (
                                <Empty description="Ссылка на оплату не получена" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal mask={false} className="modal-mini bg_chester" key="m2" title="Новая заявка" visible={props.state.modal === 'consult_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title title-big">Новая заявка</div>
                </div>
                <div className="modal-mini-body">
                    {
                        'id' in props.state.consactive ? (
                            <React.Fragment>
                                <Title level={5}>Уважаемые владельцы домашних питомцев!</Title>
                                <p>Данное сообщение является публичной офертой, перед оплатой, пожалуйста, прочитайте его внимательно. Оплата онлайн-консультации означает ваше согласие на данное предложение.</p>
                                {!props.state.moreload || !props.state.loading ?
                                    <React.Fragment>
                                        {'id' in props.state.da ? (
                                            <React.Fragment>
                                                {
                                                    props.state.consactive.info ?
                                                    (
                                                        <p className='information_conslt'><BsExclamationLg style={{color:'#ff4d4f'}} /> {props.state.consactive.info}</p>
                                                    )
                                                    :
                                                    null
                                                }
                                            </React.Fragment>
                                        ) : (
                                            <Empty description="Информация о учетной записи недоступна" />
                                        )
                                        }
                                    </React.Fragment>
                                :
                                    <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                                }
                                <Collapse accordion className="cashback_collapse" >
                                    <Panel header={<span className='cashback_info_title'><BsInfoCircle /> Кому подходит услуга онлайн-консультации?</span>} key="1">
                                        <div className='maxheight150'>
                                            <p>Владельцам животных, которые не знают или не уверены, к какому именно врачу надо обратиться в связи с возникшими проблемами у питомца. В некоторых случаях владелец может лишь примерно предположить, что болит у его животного.</p>
                                            <p>Владельцам, кто понимает, что происходит с его питомцем, и знает, к какому узкопрофильному специалисту клиники Честер следует обратиться за консультацией, например, ортопеду, неврологу или дерматологу.</p>
                                            <p>Онлайн-консультация не предназначена для оказания помощи владельцам, чьи питомцы находятся в экстренном, тяжелом, неотложном, критическом положении и могут потенциально умереть. Таких пациентов нужно везти в клинику и делать это быстро.</p>
                                        </div>
                                    </Panel>
                                    <Panel header={<span className='cashback_info_title'><BsInfoCircle /> Как происходит онлайн-консультация?</span>} key="2">
                                        <div className='maxheight150'>
                                            <p>1. Заполните форму ниже, укажите ваши контактные данные</p>
                                            <p>2. Опишите возникшую проблему, симптомы, которые вы наблюдаете у животного</p>
                                            <p>3. Оплатите консультацию</p>
                                            <p>4. Врач свяжется с вами в течение 3 часов.</p>
                                            <p>5. Длительность консультации - до 30 минут. Во время консультации вы можете отправить доктору имеющиеся у вас материалы, содержащие необходимые сведения о пациенте и развитии его заболевания. В этом случае, если врачу потребуется более подробно изучить историю болезни вашего питомца, консультация может быть приостановлена, затем возобновится снова.</p>
                                            <p>6. Дежурные врачи консультируют 24/7</p>
                                        </div>
                                    </Panel>
                                    <Panel header={<span className='cashback_info_title'><BsInfoCircle /> Важные особенности онлайн-консультации</span>} key="3">
                                        <div className='maxheight150'>
                                            <p>Мы постараемся по возможности помочь вам и вашему питомцу, но важно понимать, что определение точного диагноза и назначение правильного лечения, как правило, требует очной консультации со специалистом и далеко не во всех случаях помочь животному можно дистанционно. В связи с этим после оплаты онлайн-консультации надо быть готовым к тому, что в конечном итоге вы можете получить рекомендацию: "Приезжайте в клинику!".</p>
                                            <p>Онлайн-консультация на основе анализа жалоб и состояния вашего питомца поможет вам принять правильное решение о необходимости проведения очного приема у нужного специалиста, а в дальнейшем осуществлять медицинское наблюдение за животным и корректировать его лечение можно будет удаленно.</p>
                                            <p>Онлайн-консультация не предназначена для безусловного излечения животного от заболевания и для удаленной постановки диагноза. Это лишь первый шаг на сложном пути постановки диагноза и лечения вашего питомца. Онлайн-консультация не гарантирует постановки диагноза, и вообще, далеко не всегда врач может удаленно ставить диагноз.</p>
                                            <p>В ряде случаев часть владельцев будет разочарована консультацией, например когда весь предоставленный ими объем информации о болезни питомца окажется неинформативным и единственной рекомендацией врача станет совет о проведении правильной диагностики по месту жительства или в нашей клинике в Санкт-Петербурге. Разумеется, в этом случае будет конкретно разъяснено, что именно необходимо сделать для постановки правильного диагноза животному.</p>
                                            <p>Также завышенными окажутся ожидания тех владельцев, кто предполагает, что в рамках одной оплаченной онлайн-консультации врач сможет сразу провести точную диагностику и вылечить питомца. Нет, так не будет. Выздоровление животного - это сложный процесс, требующий совместных усилий врача и владельца животного.</p>
                                            <p>Несомненно, не следует обращаться за онлайн-консультацией (и оплачивать ее), если ваш питомец сломал лапу, выпал из окна, «ему очень плохо, лежит без движения», а также при наличии у него опухоли и т.д. (перечень подобных причин можно продолжить).</p>
                                            <p>Если доктор с первых минут общения сделает вывод, что состояние вашего питомца требует только непосредственного обращения в клинику, то перечисленные за онлайн-консультацию деньги будут учтены при расчете стоимости очного приема необходимого вам специалиста в момент посещения нашей клиники.</p>
                                        </div>
                                    </Panel>
                                </Collapse>
                                {!props.state.moreload || !props.state.loading ?
                                    <React.Fragment>
                                        {'id' in props.state.da ? (
                                            <Form name="form" form={form1} onFinish={consult_add} className="form" layout="vertical">
                                                { form1.setFieldsValue({ 
                                                    phone:props.state.da.phone,
                                                    type:props.state.consactive.id
                                                }) }
                                                <Form.Item hidden name="type" label="Услуга" rules={[{ required: true, message: 'Пожалуйста, укажите тип обращения!' }]}>
                                                    <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        { props.state.consultations.map( (item, index) => 
                                                            <OptGroup label={item.theme}>
                                                                { item.list.map( (item_two, index) => 
                                                                    <Option key={item_two.id} value={item_two.id}>{item_two.price} ₽ - {item_two.name} ({item.theme})</Option>
                                                                ) }
                                                            </OptGroup>
                                                        ) }
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name="patient" label="Пациент" rules={[{ required: true, message: 'Пожалуйста, укажите пациента!' }]}>
                                                    <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        <Option key="no" value="0">Не указано</Option>
                                                        { props.state.patients.map( (item, index) => 
                                                            <Option key={index} disabled={Number(item.block)} value={item.id}>{item.nametype} - {item.name}</Option>
                                                        ) }
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name="doctor_id" label="Врач" hidden >
                                                    <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        <Option value="0">Не выбрано</Option>
                                                        { props.state.emp[1].map( (item, index) => 
                                                            <Option key={index} value={item.id}>{item.name}</Option>
                                                        ) }
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name="descr" label="Проблема" rules={[{ required: true, message: 'Пожалуйста, укажите проблему!' }]}>
                                                    <TextArea showCount maxLength={1000} rautoSize={{ minRows: 2, maxRows: 4 }} />
                                                </Form.Item>
                                                <Form.Item name="communication" label="Предпочтительный вид связи">
                                                    <TextArea showCount maxLength={1000} autoSize={{ minRows: 1, maxRows: 4 }} />
                                                </Form.Item>
                                                <Form.Item name="phone" label="Номер для связи" rules={[{ required: true, message: 'Пожалуйста, укажите номер телефона для связи!' }]}>
                                                    <MaskedInput
                                                        prefix="+"
                                                        mask="7 111-111-11-11"
                                                        placeholder="7 999-999-99-99"
                                                        size="11"
                                                        formatCharacters={{
                                                        'W': {
                                                            validate(char) { return /\w/.test(char ) },
                                                            transform(char) { return char.toUpperCase() }
                                                        }
                                                        }}
                                                    />
                                                </Form.Item>
                                                <Form.Item name="city" label="Город">
                                                    <TextArea showCount maxLength={1000} autoSize={{ minRows: 1, maxRows: 4 }} />
                                                </Form.Item>
                                                <Divider />
                                                <Form.Item className="max400" name="processing_of_personal_data" valuePropName="checked" label='' 
                                                    rules={[
                                                        {
                                                        validator: (_, value) =>
                                                            value ? Promise.resolve() : Promise.reject(new Error('Пожалуйста, подтвердите согласие на обработку персональных данных!')),
                                                        },
                                                    ]}
                                                >
                                                    <Checkbox>
                                                        Согласен на обработку персональных данных
                                                    </Checkbox>
                                                </Form.Item>
                                                <Form.Item className="max400" name="processing_of_personal_data2" valuePropName="checked" label='' 
                                                    rules={[
                                                        {
                                                        validator: (_, value) =>
                                                            value ? Promise.resolve() : Promise.reject(new Error('Пожалуйста, подтвердите прочтение условий!')),
                                                        },
                                                    ]}
                                                >
                                                    <Checkbox>
                                                        Прочитал условия проведения онлайн-консультации и согласен с ними
                                                    </Checkbox>
                                                </Form.Item>
                                                <div className="fcenter" style={{marginTop:20}}>
                                                    <Form.Item>
                                                        <Button className="button_normal button_blue" shape="round" htmlType="submit">Подтвердить</Button>
                                                    </Form.Item>
                                                </div>
                                            </Form>
                                        ) : (
                                            <Empty description="Информация о учетной записи недоступна" />
                                        )}
                                    </React.Fragment>
                                :
                                    <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                                }
                            </React.Fragment>
                        )
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal mask={false} className="modal-mini" key="m2" title="Способ оплаты" visible={props.state.modal === 'consult_pay'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title title-big">Способ оплаты</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ? (
                        <React.Fragment>
                            {'id' in props.state.consultinfo ? (
                                <React.Fragment>
                                    <Space wrap>
                                        { props.state.paytype.map( (item, index) => 
                                            <div key={index} className="pay_button" onClick={() => pay(props.state.consultinfo.id,item.type)}>
                                                <img alt={item.type} src={item.img} />
                                            </div>
                                        ) }
                                    </Space>
                                </React.Fragment>
                            ) : (
                                <Empty description="Информация о заявке недоступна" />
                            )}
                        </React.Fragment>
                    ) : (
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    )}
                </div>
            </Modal>
            <PullToRefresh
                pullDownContent={<div className='container-prt'><FiChevronsDown className='downinfiniti' /></div>}
                releaseContent={<div className='container-prt'><FiChevronsUp className='upinfiniti' /></div>}
                refreshContent={<div className="container-prt"><FiRotateCw className='spininfiniti' /></div>}
                pullDownThreshold={50}
                onRefresh={onRefresh}
                triggerHeight={props.state.triggerHeightScroll}
                startInvisible={true}
            >
                <Row className="wowload">
                    <Col key="k2_clients" span={24} className="tooglecolor cons_block" >
                        <div className="fcenter" style={{marginBottom:20}}>
                            <Space>
                                <Button className={`button_normal ${props.state.constype === 1 ? `button_blue` : `button_gray`}`} onClick={() => consult_type(1)}>Онлайн-услуги</Button>
                                <Button hidden className={`button_normal ${props.state.constype === 2 ? `button_blue` : `button_gray`}`} onClick={() => consult_type(2)}>Активные</Button>
                                <Button className={`button_normal ${props.state.constype === 3 ? `button_blue` : `button_gray`}`} onClick={() => consult_type(3)}>История</Button>
                            </Space>
                        </div>
                        { props.state.constype === 1 ?
                            <React.Fragment>
                                <div className='consults_items_service_column'>
                                    <div className='consults_items_service'>
                                    { props.state.consultations.map( (items, index) => 
                                            <React.Fragment>
                                            { items['list'].map( (item, index) => 
                                                <div className='consults_item_service' onClick={() => consult_push_add(item)}>
                                                    <div className='consults_item_service_theme'>
                                                        {item.theme}: {item.price}₽
                                                    </div>
                                                    <div className='consults_item_service_img'>
                                                        <img src={`${props.state.api.replace(/_l/gi,'')}uploads/${item.img}`} alt={item.theme} />
                                                    </div>
                                                </div>
                                            ) }
                                            </React.Fragment>
                                    ) }

                                        </div>
                                </div>
                            </React.Fragment>
                        :
                            null
                        }
                        { props.state.constype === 2 || props.state.constype === 3 ?
                            <React.Fragment>
                                { props.state.consult.length > 0 ?
                                    <StackGrid
                                        gutterWidth={20}
                                        gutterHeight={20}
                                        columnWidth={
                                            props.width <= 768 ? '100%' : props.width <= 1000 ? '50%' : props.width <= 1200 ? '33.33%' : '25%'
                                        }
                                    >
                                        {props.state.consult.map( item => 
                                            <Card 
                                                title="Онлайн-консультация" 
                                                bordered={false}
                                                key={item.id}
                                            >
                                                <Space direction="vertical" style={{width:'100%'}}>
                                                    <Row style={{display:'none'}}>
                                                        <Col flex="1" style={{marginRight:10, display:'flex'}}><span className='cons_date'>Дата заявки: {props.lasttime(item.date,'Неизвестно','last')}</span></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="gutter-row">
                                                            <Space direction="vertical" size="small" style={{gap:0}}>
                                                                <Text
                                                                    strong
                                                                    style={{
                                                                        fontSize: 16,
                                                                    }}
                                                                    className="cons_title"
                                                                >
                                                                    Питомец:
                                                                </Text>
                                                                <Text>
                                                                    {item.patient_name ?
                                                                        <React.Fragment>
                                                                            {item.patient_type ? 
                                                                                item.patient_type + ' ' + item.patient_name
                                                                                :
                                                                                item.patient_name
                                                                            }
                                                                        </React.Fragment>
                                                                    : 
                                                                        'не указано'
                                                                    }
                                                                </Text>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="gutter-row">
                                                            <Space direction="vertical" size="small" style={{gap:0}}>
                                                                <Text
                                                                    strong
                                                                    style={{
                                                                        fontSize: 16,
                                                                    }}
                                                                    className="cons_title"
                                                                >
                                                                    Проблема:
                                                                </Text>
                                                                <Text
                                                                    strong
                                                                    style={{
                                                                        fontSize: 14,
                                                                    }}
                                                                >
                                                                    {item.descr ? item.descr : 'Без описания'}
                                                                </Text>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={16} style={{marginBottom:10}} >
                                                        <Col className="gutter-row" span={12}>
                                                            <Space direction="vertical" size="small" style={{gap:0}}>
                                                                <Text
                                                                    strong
                                                                    style={{
                                                                        fontSize: 16,
                                                                    }}
                                                                    className="cons_title"
                                                                >
                                                                    Дата создания:
                                                                </Text>
                                                                <Text>
                                                                    {item.date > 0 ? props.lasttime(item.date,'Не определена','last') : <Text className="bold" type="danger">Не определена</Text>}
                                                                </Text>
                                                            </Space>
                                                        </Col>
                                                        <Col className="gutter-row" span={12}>
                                                            <Space direction="vertical" size="small" style={{gap:0}}>
                                                                <Text
                                                                    strong
                                                                    style={{
                                                                        fontSize: 16,
                                                                    }}
                                                                    className="cons_title"
                                                                >
                                                                    Время:
                                                                </Text>
                                                                <Text>
                                                                    {item.date > 0 ? props.lasttimee(item.date,'не определено','last') : <Text className="bold" type="danger">не определено</Text>}
                                                                </Text>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={16} style={{marginBottom:10}} >
                                                        <Col className="gutter-row" span={12}>
                                                            <Space direction="vertical" size="small" style={{gap:0}}>
                                                                <Text
                                                                    strong
                                                                    style={{
                                                                        fontSize: 16,
                                                                    }}
                                                                    className="cons_title"
                                                                >
                                                                    Статус:
                                                                </Text>
                                                                <Text>
                                                                    {titleblock(item.status)}
                                                                </Text>
                                                            </Space>
                                                        </Col>
                                                        <Col className="gutter-row" span={12}>
                                                            <Space direction="vertical" size="small" style={{gap:0}}>
                                                                <Text
                                                                    strong
                                                                    style={{
                                                                        fontSize: 16,
                                                                    }}
                                                                    className="cons_title"
                                                                >
                                                                    Стоимость:
                                                                </Text>
                                                                <Text>
                                                                    {price_con(item.money)} руб.
                                                                </Text>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                    {['unpaid','pending'].indexOf( item.status ) != -1 ? (
                                                        <div className="fcenter">
                                                            <Button className="button_normal button_red" onClick={() => pay(item.id)}>Оплатить</Button>
                                                        </div>
                                                    ) : (
                                                        null
                                                    )}
                                                    <Row>
                                                    </Row>
                                                </Space>

                                            </Card>
                                        )}
                                    </StackGrid>
                                :
                                    <div className="fcenter"><Empty /></div>
                                }
                            </React.Fragment>
                        :
                            null
                        }
                    </Col>
                </Row>
            </PullToRefresh>
        </React.Fragment>
    )
};

export default Global;
import moment from 'moment';
import TimeField from 'react-simple-timefield';
import React from 'react';
import StackGrid from "react-stack-grid";
import MaskedInput from 'antd-mask-input'
import { Select, Row, Col, Empty, DatePicker, Typography, Space, Card, Button, Modal, Divider, Form, Popover, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

moment.locale('ru');

const { Option } = Select;
const { Text } = Typography;

const Global = props => {

    const [form1] = Form.useForm();

    async function recordlocal_add(v) {
        v['method'] = 'recordlocal_add';
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        await props.api(v,true);
    }

    return (
        <React.Fragment>
            <Row style={{justifyContent:'center'}} className="wowload">
                <Col flex="0 0 700px" className="shadowscreen recordlocalcol" >
                    <div className='recordlocalcol_title'>Онлайн-заявка</div>
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'id' in props.state.da ? (
                                <Form name="form" form={form1} onFinish={recordlocal_add} className="form" layout="vertical">
                                    { form1.setFieldsValue({ 
                                        phone:props.state.da.phone
                                    }) }
                                    <Form.Item name="patient" label="Ваше животное" rules={[{ required: true, message: 'Пожалуйста, укажите пациента!' }]}>
                                        <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            { props.state.patients.map( (item, index) => 
                                                <Option key={index} disabled={Number(item.block)} value={item.id}>
                                                    {item.name} - {item.nametype}
                                                </Option>
                                            ) }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="complaint" label="Комментарий к заявке">
                                        <TextArea showCount maxLength={1000} rows={4} />
                                    </Form.Item>
                                    <Form.Item name="phone" label="Номер для связи" rules={[{ required: true, message: 'Пожалуйста, укажите номер телефона для связи!' }]}>
                                        <MaskedInput
                                            prefix="+"
                                            mask="7 111-111-11-11"
                                            placeholder="7 999-999-99-99"
                                            size="11"
                                            formatCharacters={{
                                            'W': {
                                                validate(char) { return /\w/.test(char ) },
                                                transform(char) { return char.toUpperCase() }
                                            }
                                            }}
                                        />
                                    </Form.Item>
                                    <div className="fcenter">
                                        <Form.Item>
                                            <Button className="button_normal button_blue" shape="round" htmlType="submit">Отправить</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            ) : (
                                <Empty description="Информация о учетной записи недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;
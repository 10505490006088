import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { Space, Typography, Row, Col, Modal, Divider, List, Avatar } from 'antd';
import Icon from '@ant-design/icons';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { FaSyringe, FaTooth } from "react-icons/fa";
import { RiScissorsFill } from "react-icons/ri";
import { MdOutlineMedicalServices } from "react-icons/md";
import { FiRotateCw, FiChevronsUp, FiChevronsDown } from "react-icons/fi";
import {PullToRefresh} from "react-js-pull-to-refresh";
import SVG from "react-inlinesvg";
import vaccination from "../../img/icons/svg/vaccination.svg";
import ultrasound from "../../img/icons/svg/ultrasound.svg";
import sterilization_suki from "../../img/icons/svg/sterilization_suki.svg";
import sterilization_coshka from "../../img/icons/svg/sterilization_coshka.svg";
import researches from "../../img/icons/svg/researches.svg";
import rehabilitation from "../../img/icons/svg/rehabilitation.svg";
import grooming from "../../img/icons/svg/grooming.svg";
import dermatology from "../../img/icons/svg/dermatology.svg";
import consultation from "../../img/icons/svg/consultation.svg";
import chipping from "../../img/icons/svg/chipping.svg";
import castration_cote from "../../img/icons/svg/castration_cote.svg";
import castration_cobel from "../../img/icons/svg/castration_cobel.svg";

moment.locale('ru');
const localizer = momentLocalizer(moment);

const { Text } = Typography;

function cal_icons(n,c,s) {
    switch(n) {
        case 'vaccination': return <Icon component={() => <SVG src={vaccination} fill={c} style={{color:c,width:'1em',height:'1em'}} />} style={{color:c,fontSize:s}} />;
        case 'ultrasound': return <Icon component={() => <SVG src={ultrasound} fill={c} style={{color:c,width:'1em',height:'1em'}} />} style={{color:c,fontSize:s}} />;
        case 'sterilization_suki': return <Icon component={() => <SVG src={sterilization_suki} fill={c} style={{color:c,width:'1em',height:'1em'}} />} style={{color:c,fontSize:s}} />;
        case 'sterilization_coshka': return <Icon component={() => <SVG src={sterilization_coshka} fill={c} style={{color:c,width:'1em',height:'1em'}} />} style={{color:c,fontSize:s}} />;
        case 'researches': return <Icon component={() => <SVG src={researches} fill={c} style={{color:c,width:'1em',height:'1em'}} />} style={{color:c,fontSize:s}} />;
        case 'rehabilitation': return <Icon component={() => <SVG src={rehabilitation} fill={c} style={{color:c,width:'1em',height:'1em'}} />} style={{color:c,fontSize:s}} />;
        case 'grooming': return <Icon component={() => <SVG src={grooming} fill={c} style={{color:c,width:'1em',height:'1em'}} />} style={{color:c,fontSize:s}} />;
        case 'dermatology': return <Icon component={() => <SVG src={dermatology} fill={c} style={{color:c,width:'1em',height:'1em'}} />} style={{color:c,fontSize:s}} />;
        case 'consultation': return <Icon component={() => <SVG src={consultation} fill={c} style={{color:c,width:'1em',height:'1em'}} />} style={{color:c,fontSize:s}} />;
        case 'chipping': return <Icon component={() => <SVG src={chipping} fill={c} style={{color:c,width:'1em',height:'1em'}} />} style={{color:c,fontSize:s}} />;
        case 'castration_cote': return <Icon component={() => <SVG src={castration_cote} fill={c} style={{color:c,width:'1em',height:'1em'}} />} style={{color:c,fontSize:s}} />;
        case 'castration_cobel': return <Icon component={() => <SVG src={castration_cobel} fill={c} style={{color:c,width:'1em',height:'1em'}} />} style={{color:c,fontSize:s}} />;
        default: return <MdOutlineMedicalServices style={{color:c,fontSize:s}} />;
    }
}

var eventicons = {};

const CustomEvent = (event) => {
    console.log(event,eventicons);
    console.log('Start',event.event.title.text.icon,Date.parse(event.event.start)/1000);
    if (Date.parse(event.event.start)/1000 in eventicons) {
        console.log('Skip',event.event.title.text.icon,Date.parse(event.event.start)/1000);
        if (eventicons[Date.parse(event.event.start)/1000] !== event.event.title.id) {
            return null;
        }
    } else {
        eventicons[Date.parse(event.event.start)/1000] = event.event.title.id;
        console.log('Add',event.event.title.text.icon,Date.parse(event.event.start)/1000);
    }
    console.log('Render',event.event.title.text.icon,Date.parse(event.event.start)/1000);
    return cal_icons(event.title.text.icon,'#2F394D','20px');
}

const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate('prev');
    };
  
    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate('next');
    };
  
    // const goToCurrent = () => {
    //   const now = new Date();
    //   toolbar.date.setMonth(now.getMonth());
    //   toolbar.date.setYear(now.getFullYear());
    //   toolbar.onNavigate('current');
    // };
  
    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
      );
    };
  
    return (
      <div className='toolbar-container-custom'>
        <FiChevronLeft onClick={goToBack} className="toolbar-button-custom" />
        <span className='label-date'>{label()}</span>
        <FiChevronRight onClick={goToNext} className="toolbar-button-custom" />
      </div >
    );
};

// const EventAgenda = (event) => {
//     return  <span>
//                 <p>Текст</p>
//             </span>
// }

// function eventWrapper(props) {
//     // Some data that you might have inserted into the event object
//     const data = props.event;
//     const customDiv = (
//       <div className="yourClass">
//         <span>{data.someField}</span>
//         <span>{data.anotherField}</span>
//       </div>
//     );
//     const eventDiv = React.cloneElement(props.children.props.children, {}, customDiv);
//     const wrapper = React.cloneElement(props.children, {}, eventDiv);
//     return (<div>
//       {wrapper}
//     </div>
//     );
// }

const Global = props => {

    const [calendarevents, setCalendarevents] = React.useState([]);
    //  const [eventdate, setEventdate] = React.useState(new Date());

    function titleblock(n) {
        switch(n) {
          case '0': return <span>приём открыт</span>;
          case '1': return <span>приём завершен</span>;
          case '51': return <span>рекомендуется</span>;
          default: return <span>неизвестный статус</span>;
        }
    }

    var obj = {
        //* Поиск
        get list () {
            if ( props.state.calendar.length > 0 ) {
                let full = props.state.calendar;
                for (var i=0, len=full.length; i<len; i++) {
                    full[i]['start'] = new Date(full[i]['start']);
                    full[i]['end'] = new Date(full[i]['end']);
                }
                return full;            
            } else {
                return props.state.calendar;
            }
        }
    }

    async function viewevents(events,date) {
        setCalendarevents(events);
    }

    // const handleSelectSlot = ({start,end,resourceId}) => {
    //     console.log("Selected", start, end, resourceId);
    // }

    async function start_reload(type) {
        await props.api({ 'method':'calendar' },false);
        return true;
    }

    function onRefresh() {
        return start_reload();
    }

    return (
        <React.Fragment>
            <Modal mask={false} width={500} className="modal-mini bg_chester" title="Просмотр событий" visible={props.state.modal === 'calendar_events'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Просмотр событий</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Space direction="vertical" style={{width:'100%'}}>
                            {
                                props.state.events.map( (item, index) => 
                                    <React.Fragment>
                                        <Space wrap direction="vertical" style={{width:'100%'}}>
                                            <Space direction="horizontal" wrap style={{width:'100%'}}>
                                                <Text strong>Услуга: </Text>
                                                <pre><Text italic>{item.title.text.name}</Text></pre>
                                            </Space>
                                            {item.title.text.text !== '' ? 
                                                <Space direction="horizontal" wrap style={{width:'100%'}}>
                                                    <Text strong>Подробнее: </Text>
                                                    <pre><Text italic>{item.title.text.text}</Text></pre>
                                                </Space>
                                            : 
                                                null
                                            }
                                            <Space direction="horizontal" wrap style={{width:'100%'}}>
                                                <Text strong>Дата: </Text>
                                                <Text>{moment(item.start).format("DD/MM/YYYY hh:mm")}</Text>
                                            </Space>
                                            <div className="fcenter">
                                                {titleblock(item.title.status)}
                                            </div>
                                        </Space>
                                        <Divider />
                                    </React.Fragment>
                                )
                            }
                        </Space>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <PullToRefresh
                pullDownContent={<div className='container-prt'><FiChevronsDown className='downinfiniti' /></div>}
                releaseContent={<div className='container-prt'><FiChevronsUp className='upinfiniti' /></div>}
                refreshContent={<div className="container-prt"><FiRotateCw className='spininfiniti' /></div>}
                pullDownThreshold={50}
                onRefresh={onRefresh}
                triggerHeight={props.state.triggerHeightScroll}
                startInvisible={true}
            >
                <Row className="wowload" style={{flexDirection:'column'}}>
                    <Col key="k2_clients" className="shadowscreen calendar_col" >
                        <p className='calendar_title_text'>Здесь отмечены пройденные и рекомендованные приемы</p>
                        <Calendar
                            events={obj.list}
                            views={['month']}
                            components={{
                                event:CustomEvent,
                                toolbar:CustomToolbar
                            }}
                            localizer={localizer}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 500 }}
                            onShowMore={(events, date) => viewevents(events,date)}
                            onSelectSlot={(slotInfo) => {
                                const { start, end } = slotInfo;
                                const eventsForThisDay = obj.list.filter(
                                event => event.start >= start && event.start < end
                                );
                                setCalendarevents(eventsForThisDay);
                                console.log(slotInfo,eventsForThisDay)
                            }}
                            onSelectEvent={(slotInfo) => {
                                const { start, end } = slotInfo;
                                var temp = new Date(((Date.parse(end)/1000)+86400)*1000);
                                //temp.setDate(temp.getDate() + 1);
                                const eventsForThisDay = obj.list.filter(
                                event => event.start >= start && event.start < temp
                                );
                                setCalendarevents(eventsForThisDay);
                                console.log(slotInfo,eventsForThisDay)
                            }}
                            selectable
                            messages={{
                                next: ">",
                                previous: "<",
                                today: "Сегодня",
                                month: "Мес",
                                week: "Нед",
                                day: "День",
                                agenda: "События",
                                date: "Дата",
                                time: "Время",
                                event: "Событие",
                                showMore: (target) => <span className="ml-2" role="presentation" > ...{target}{props.decnum(target,[ ' событие',' события',' событий' ])}</span>
                            }}
                            headerToolbar={{
                                start: "",
                                center: "prev, title, next",
                                end: ""
                            }}
                        />
                    </Col>
                    {calendarevents.length > 0 ?
                        <div className='calendar_info'>
                            <p className='calendar_title_events'>События <span className='calendar_hide' onClick={() => setCalendarevents([])}>скрыть</span></p>
                            <Col key="k2_clients" className="shadowscreen calendar_block" >
                                {!props.state.moreload || !props.state.loading ?
                                    <Space direction="vertical" style={{width:'100%'}}>
                                        <List
                                            className="event_full_list"
                                            itemLayout="horizontal"
                                            dataSource={calendarevents}
                                            renderItem={item => (
                                            <List.Item
                                                
                                            >
                                                <List.Item.Meta
                                                    avatar={<Avatar size={64} style={{ backgroundColor: 'transparent', verticalAlign: 'middle' }} icon={cal_icons(item.title.text.icon,'#ea7383','30px')} />}
                                                    title={<span className='event_title'>{item.title.text.name}</span>}
                                                    description={
                                                        <div className='event_body'>
                                                            {item.title.text.text !== '' ? 
                                                                <Space direction="horizontal" wrap style={{width:'100%'}}>
                                                                    <pre>{item.title.text.text}</pre>
                                                                </Space>
                                                            : 
                                                                null
                                                            }
                                                            {item.title.status !== '51' ?
                                                                <React.Fragment>
                                                                    <p>Врач: {item.title.text.emp1_name ? item.title.text.emp1_name : 'не назначен'}</p>
                                                                    <p style={{display:'flex',alignItems:'center'}}>{moment(item.title.text.date2*1000).format("hh:mm")} <IoMdNotificationsOutline style={{marginLeft:'30px',fontSize:'18px'}} /></p>
                                                                    <p>Статус: {titleblock(item.title.status)}</p>
                                                                </React.Fragment>
                                                            :
                                                                <React.Fragment>
                                                                    <p></p>
                                                                    <p>Самое время оказать данную услугу Вашему питомцу</p>
                                                                </React.Fragment>
                                                            }
                                                        </div>
                                                    }
                                                />
                                            </List.Item>
                                            )}
                                        />
                                    </Space>
                                :
                                    <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                                }
                            </Col>
                        </div>
                    :
                    null
                    }
                </Row>    
            </PullToRefresh>
        </React.Fragment>
    )
};

export default Global;
import React from 'react';
import StackGrid from "react-stack-grid";
import { Row, Col, Space, List, Card, Empty, Button } from 'antd';
import { FiRotateCw, FiChevronsUp, FiChevronsDown } from "react-icons/fi";
import {PullToRefresh} from "react-js-pull-to-refresh";


const Global = props => {

    //* Выбор элемента для обновления
    // async function patupd(v) {
    //      // Добавить форму
    //     form2.setFieldsValue({ svid:v.id, name:v.name, descr:v.descr, price: v.price, multi: v.multi });
    // }

    // function price_con(val) {
    //     return val.replace(/[^\d]/g, '').replace(/\B(?=(?:\d{3})+(?!\d))/g, ' ');
    // }

    async function start_reload(type) {
        await props.api({ 'method':'services' },false);
        return true;
    }

    function onRefresh() {
        return start_reload();
    }

    return (
        <React.Fragment>
            <PullToRefresh
                pullDownContent={<div className='container-prt'><FiChevronsDown className='downinfiniti' /></div>}
                releaseContent={<div className='container-prt'><FiChevronsUp className='upinfiniti' /></div>}
                refreshContent={<div className="container-prt"><FiRotateCw className='spininfiniti' /></div>}
                pullDownThreshold={50}
                onRefresh={onRefresh}
                triggerHeight={props.state.triggerHeightScroll}
                startInvisible={true}
            >
                <div className="fcenter" style={{marginBottom:'20px'}}>
                    <Button className="button_normal button_red" href="https://chester-vet.ru/?page_id=109#price" target="_blank" >Полный прайс</Button>
                </div>
                <div className="fcenter" style={{marginBottom:'20px'}}>
                    <span style={{color:'#ff6347',fontSize:20}}>Обратите, пожалуйста, внимание, цены указаны на самые востребованные услуги.</span>
                </div>
                <Row className="wowload">
                    <Col key="k2_clients" span={24} className="padding_panel tooglecolor" >
                        { props.state.services.length > 0 ?
                            <StackGrid
                                gutterWidth={20}
                                gutterHeight={20}
                                columnWidth={
                                    props.width <= 768 ? '100%' : props.width <= 1000 ? '50%' : props.width <= 1200 ? '33.33%' : '25%'
                                }
                            >
                                {props.state.services.map( item => 
                                    <Card 
                                        title={item.name} 
                                        bordered={false}
                                        key={item.id}
                                    >
                                        <List
                                            locale={{ emptyText: 
                                                <Empty description='Список услуг пуст' />
                                            }}
                                            split={false}
                                            itemLayout="horizontal"
                                            dataSource={item.list}
                                            renderItem={(list,index) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        description={
                                                            <Space direction="vertical" className="serv-left">
                                                                <pre>{list.descr}</pre>
                                                            </Space>
                                                        }
                                                    />
                                                    <div className="service_price">{list.price > 0 ? list.price+'₽' : (list.price < 0 ? 'Бесплатно' : '')}</div>
                                                </List.Item>
                                            )}
                                        />
                                    </Card>
                                )}
                            </StackGrid>
                        :
                            <div className="fcenter"><Empty /></div>
                        }
                    </Col>
                </Row>    
            </PullToRefresh>
        </React.Fragment>
    )
};

export default Global;
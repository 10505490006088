import React from 'react';
import { Row, Col, Empty, Collapse, Typography, List, Space } from 'antd';
import { BsInfoCircle } from "react-icons/bs";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { FiRotateCw, FiChevronsUp, FiChevronsDown } from "react-icons/fi";
import {PullToRefresh} from "react-js-pull-to-refresh";
import { HiOutlinePlusSm, HiOutlineMinusSm } from "react-icons/hi";

const { Text, Title } = Typography;
const { Panel } = Collapse;

const Global = props => {

    const [hideloader, setHideloader] = React.useState(true);

    function typeblock(n) {
        switch(n) {
          case '1': return <span>+</span>;
          case '2': return <span>-</span>;
          default: return <span>?</span>;
        }
    }

    function price(val) {
        return val.replace(/[^\d]/g, '').replace(/\B(?=(?:\d{3})+(?!\d))/g, ' ');
    }

    async function start_reload() {
        await props.api({ 'method':'cashback' },false);
        return true;
    }

    function onRefresh() {
        return start_reload();
    }

    return (
        <React.Fragment>
            <PullToRefresh
                pullDownContent={<div className='container-prt'><FiChevronsDown className='downinfiniti' /></div>}
                releaseContent={<div className='container-prt'><FiChevronsUp className='upinfiniti' /></div>}
                refreshContent={<div className="container-prt"><FiRotateCw className='spininfiniti' /></div>}
                pullDownThreshold={50}
                onRefresh={onRefresh}
                triggerHeight={props.state.triggerHeightScroll}
                startInvisible={true}
                className={hideloader ? 'hideloader' : null}
            >
                <Row className="wowload">
                    {!props.state.moreload || !props.state.loading ? (
                        <React.Fragment>
                            <Space style={{width:'100%'}} direction="vertical">
                                <p className='cashback_count' >{price(props.state.da['cashback'])}{props.decnum(props.state.da['cashback'],[ ' БОНУС',' БОНУСА',' БОНУСОВ' ])}</p>
                                <Collapse accordion className="cashback_collapse" >
                                    <Panel header={<span className='cashback_info_title'><BsInfoCircle /> Правила начисления и списания</span>} key="1">
                                        <Title level={5}><span className='text_icon'><HiOutlinePlusSm style={{color:'#1e2022',fontSize:'20px',verticalAlign:'bottom'}} /> Начисление</span></Title>
                                        <p>За все окзанные услуги начисляется {props.state.cashback_faq['part']}%</p>
                                        <Title level={5}><span className='text_icon'><HiOutlineMinusSm style={{color:'#1e2022',fontSize:'20px',verticalAlign:'bottom'}} /> Списание</span></Title>
                                        <p>Оплатить бонусами можно до {props.state.cashback_faq['limit']}% от стоимости услуги</p>
                                    </Panel>
                                </Collapse>
                                <Col key="k2_clients" span={24} className="shadowscreen" >
                                    <List
                                        style={{padding:'20px'}}
                                        className="cashback_list"
                                        itemLayout="horizontal"
                                        dataSource={props.state.cashback}
                                        locale={{ emptyText: <Empty description="Список пуст" />}} 
                                        renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                            title={item.date > 0 ? props.lasttime(item.date,'Не отправлено','last') : <Text className="bold" type="danger">—</Text>}
                                            description={item.text}
                                            />
                                            <div className='cashback_sum'>{typeblock(item.type)}{item.count}</div>
                                        </List.Item>
                                        )}
                                    />
                                </Col>
                            </Space>
                        </React.Fragment>
                    ) : (
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    )}
                </Row>    
            </PullToRefresh>
        </React.Fragment>
    )
};

export default Global;
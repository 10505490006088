import React from 'react';
import MaskedInput from 'antd-mask-input';
import { Typography, Layout, Form, Input, Button, Modal, DatePicker, Divider, Select, message, Checkbox } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
const { Content } = Layout;

const { Option } = Select;
const { Text } = Typography;

const Global = props => {

    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [more, setMore] = React.useState(0);

    //* Отправка формы пароля
    async function newpass(v) {
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        v['method'] = 'newpass';
        await props.api(v,true);
    }

    //* Отправка формы пароля
    async function newprofile(v) {
        if (v['password'] !== v['password_two']) {
            message.error('Пароли не совпадают!');
            console.log(v['password'],v['password_two']);
            return true;
        }
        v['method'] = 'registration';
        v['birthday'] = Date.parse(v['birthday'])/1000;
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        await props.setState( {
            tempauth:[
                v['phone'],
                v['password'],
                ''
            ]
        } );
        await props.api(v,true);
    }

    return (
        <Layout style={{ minHeight: "100vh" }} className="bg-auth">
            <Content style={{ margin: '24px 16px 0' }} >
                <Modal mask={false} key="m1" className="modal-mini bg_chester" title="Восстановление пароля" visible={props.state.modal === 'newpass'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                    <div className="modal-mini-header">
                        <div className="modal-mini-header-title">Восстановление пароля</div>
                    </div>
                    <div className="modal-mini-body">
                        <Form name="form" form={form} onFinish={newpass} className="form">
                            <Form.Item name={`phone`} label='Телефон' rules={[{ required: true, message: `Пожалуйста, введите номер телефона!` }]}>
                                <MaskedInput
                                    prefix="+"
                                    mask="7 111-111-11-11"
                                    placeholder="7 999-999-99-99"
                                    size="11"
                                    formatCharacters={{
                                    'W': {
                                        validate(char) { return /\w/.test(char ) },
                                        transform(char) { return char.toUpperCase() }
                                    }
                                    }}
                                />
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="button_normal button_blue" htmlType="submit">Запросить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </Modal>
                <Modal mask={false} key="m2" className="modal-mini bg_chester" title="Регистрация" visible={props.state.modal === 'newprofile'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                    <div className="modal-mini-header">
                        <div className="modal-mini-header-title">Регистрация</div>
                    </div>
                    <div className="modal-mini-body">
                        <Form name="form" form={form2} onFinish={newprofile} className="form">
                            <Form.Item className="max400" name="lastname" label="Фамилия" rules={[{ required: true, message: 'Пожалуйста, укажите фамилию клиента!' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item className="max400" name="firstname" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя клиента!' }]}>
                                <Input />
                            </Form.Item>
                            {
                                more ?
                                <Form.Item className="max400" name="patronymic" label="Отчество" >
                                    <Input />
                                </Form.Item> :
                                null
                            }
                            {
                                more ?
                                <Form.Item className="max400" name="birthday" label="Дата рождения" >
                                    <DatePicker 
                                        format="DD.MM.YYYY"
                                        className="datepickerfull"
                                    />
                                </Form.Item> :
                                null
                            }
                            {
                                more ?
                                <Form.Item className="max400" name="address" label="Адрес" >
                                    <Input />
                                </Form.Item> :
                                null
                            }
                            <Form.Item className="max400" name={`phone`} label='Телефон' rules={[{ required: true, message: `Пожалуйста, введите номер телефона!` }]}>
                                <MaskedInput
                                    prefix="+"
                                    mask="7 111-111-11-11"
                                    placeholder="7 999-999-99-99"
                                    size="11"
                                    formatCharacters={{
                                    'W': {
                                        validate(char) { return /\w/.test(char ) },
                                        transform(char) { return char.toUpperCase() }
                                    }
                                    }}
                                />
                            </Form.Item>
                            {
                                more ?
                                <Form.Item className="max400" name="email" label="E-mail" >
                                    <Input />
                                </Form.Item> :
                                null
                            }
                            <Form.Item className="max400" name="sex" label="Пол" rules={[{ required: true, message: 'Пожалуйста, укажите пол клиента!' }]}>
                                <Select>
                                    <Option value="0">Неизвестно</Option>
                                    <Option value="1">Мужской</Option>
                                    <Option value="2">Женский</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item className="max400" name="password" label='Пароль' rules={[{ required: true, message: 'Пожалуйста, введине пароль!' }]} >
                                <Input.Password type="password" placeholder="Пароль" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                            </Form.Item>
                            <Form.Item className="max400" name="password_two" label='Повторите пароль' rules={[{ required: true, message: 'Пожалуйста, введине пароль!' }]} >
                                <Input.Password type="password" placeholder="Пароль" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                            </Form.Item>
                            {
                                !more ? (
                                    <div className="fcenter authp" style={{marginTop:'10px'}}>
                                        <Button onClick={() => setMore(1)} className="inreg">Познакомимся лучше?</Button>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                            <Divider />
                            <Form.Item className="max400" name="processing_of_personal_data" valuePropName="checked" label='' 
                                rules={[
                                    {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error('Пожалуйста, подтвердите согласие на обработку персональных данных!')),
                                    },
                                ]}
                            >
                                <Checkbox>
                                    Согласен на обработку персональных данных
                                </Checkbox>
                            </Form.Item>
                            <div className="fcenter" style={{marginTop:20}}>
                                <Form.Item>
                                    <Button className="button_normal button_blue" htmlType="submit">Продолжить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </Modal>
                <div className="authpage">
                    <div className="authpageblock wowload">
                        <p className="authtitle">Личный кабинет клиента</p>
                        <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={props.f_Login} >
                            <Form.Item name="phone" rules={[{ required: true, message: 'Пожалуйста, введите номер!' }]} autoComplete="off" >
                                <MaskedInput
                                    prefix="+"
                                    mask="7 111-111-11-11"
                                    className="inauth"
                                    placeholder="7 999-999-99-99"
                                    size="11"
                                    formatCharacters={{
                                        'W': {
                                            validate(char) { return /\w/.test(char ) },
                                            transform(char) { return char.toUpperCase() }
                                        }
                                    }}
                                    autoComplete="off"
                                />
                            </Form.Item>
                            <Form.Item name="password" rules={[{ required: true, message: 'Пожалуйста, введине пароль!' }]} >
                                <Input.Password className="inauth" type="password" placeholder="Пароль" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                            </Form.Item>
                            <div className="fcenter authp">
                                <Text onClick={() => props.modal('newpass')} underline>Забыли пароль</Text>
                            </div>
                            <div className="fcenter">
                                <Form.Item>
                                    <Button htmlType="submit" className="inauths">Вход</Button>
                                </Form.Item>
                            </div>
                            <div className="fcenter authp" style={{marginTop:'10px'}}>
                                <Button onClick={() => props.modal('newprofile')} className="inreg">Регистрация</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Content>
        </Layout>
    )
};

export default Global;
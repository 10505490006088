import React from 'react';
import { Menu, Divider, Popconfirm } from 'antd';

const Global = props => {

    const onClose = () => {
        props.setState( {leftmenu: 0} );
    };
    
    return (
        <React.Fragment>
            <div className={`custom-left-menu${props.state.leftmenu ? ' open' : ''}`}>
                <div className='menu-mask' onClick={() => onClose()}></div>
                <div className='menu-body'>
                    <div className="logomenu" />
                    <Menu mode="vertical">
                        { props.state.menu.length > 0 &&
                            props.state.menu.sort(function(a,b){ return a.sort - b.sort }).map( list => <Menu.Item icon={props.icons(list.icon)}  key={list.id} onClick={(e) => props.menugo(e.key)}>{list.name}</Menu.Item>)
                        }
                        <Divider className="dividerwhite" />
                        <Popconfirm placement="top" title='Вы действительно хотите выйти?' onConfirm={() => props.menugo('0')} okText="Да" cancelText="Нет">
                            <Menu.Item icon={props.icons('0')} >Выход</Menu.Item>
                        </Popconfirm>
                    </Menu>
                </div>
            </div>
        </React.Fragment>
    )
};

export default Global;
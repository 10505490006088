import moment from 'moment';
import React from 'react';
import MaskedInput from 'antd-mask-input'
import { Result, Form, Row, Col, Typography, Space, DatePicker, Select, Divider, Button, Modal, Input, Empty, Popconfirm } from 'antd';
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
//import ReactPullToRefresh from "react-pull-to-refresh";
import { FiRotateCw, FiChevronsUp, FiChevronsDown } from "react-icons/fi";

import Icon from '@ant-design/icons';
import SVG from "react-inlinesvg";
import man from "../../img/icons/svg/man.svg";
import girl from "../../img/icons/svg/girl.svg";

import {PullToRefresh} from "react-js-pull-to-refresh";
//import {PullDownContent, ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";

moment.locale('ru');

const { Option } = Select;
const { Paragraph, Text, Title } = Typography;

//* Полное время 
function fulltime(time) {
    let date = new Date(Number(time)*1000);
    let d1 = (date.getDate()<10?'0':'') + date.getDate();
    let d2 = date.getMonth()+1;
    d2 = (d2<10?'0':'') + d2;
    let d3 = date.getFullYear();
    let d4 = (date.getHours()<10?'0':'') + date.getHours();
    let d5 = (date.getMinutes()<10?'0':'') + date.getMinutes();
    return `${d1}/${d2}/${d3} ${d4}:${d5}`;
}

//* Приветствие
function hello() {
    var MyDate = new Date(),
    MyHours = MyDate.getHours(),
    hello = 'Доброго времени суток,';
    switch (true){
        case (MyHours >= 5) && (MyHours < 11):hello = 'Доброе утро,';
        break;
        case (MyHours >= 11) && (MyHours < 16):hello = 'Добрый день,';
        break;
        case (MyHours >= 16) && (MyHours <= 23):hello = 'Добрый вечер,';
        break;
        case (MyHours >= 0) && (MyHours < 5):hello = 'Доброй ночи,';
        break;
        default:hello = 'Доброго времени суток,';
        break;
    }
    return hello;
  }

const Global = props => {

    const [form] = Form.useForm();
    const [form2] = Form.useForm();

    async function profilepass(v) {
      v['method'] = 'client_pas';
      await props.api(v,true);
    }

    async function profileupd(v) {
        v['birthday'] = Date.parse(v['birthday'])/1000;
        v['phone'] = Number((v['phone']).replace(/\D+/g,""));
        v['method'] = 'client_upd';
        await props.api(v,true);
    }

    async function profiledel() {
        let v = {};
        v['method'] = 'client_del';
        await props.api(v,true);
        props.menugo('0');
    }

    async function start_reload(type) {
        await props.api({ 'method':'profile' },false);
        return true;
    }

    // function handleRefresh(resolve, reject) {
    //     setTimeout(function () {
    //         start_reload() ? resolve() : reject();
    //     }, 100);
    // }

    function onRefresh() {
        return start_reload();
    }

    return (
        <React.Fragment>
            <Modal mask={false} className="modal-mini" key="m1" title="Бонусная система" visible={props.state.modal === 'profile_faq_bonus'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title title-big">Бонусная система</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            <Title level={5}><TiArrowSortedUp style={{color:'#4bd668',fontSize:'20px',verticalAlign:'bottom'}} /> Начисление</Title>
                            <p>За все окзанные услуги начисляется {props.state.cashback_faq['part']}%</p>
                            <Title level={5}><TiArrowSortedDown style={{color:'#4bd668',fontSize:'20px',verticalAlign:'bottom'}} /> Списание</Title>
                            <p>Оплатить бонусами можно до {props.state.cashback_faq['limit']}% от стоимости услуги</p>
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal mask={false} className="modal-mini" key="m2" title="Смена пароля" visible={props.state.modal === 'profile_pas'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title title-big">Смена пароля</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form} onFinish={profilepass} className="form" layout="vertical">
                            <Form.Item name='pass' label="Пароль">
                                <Input.Password />
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="button_normal button_blue" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal mask={false} className="modal-mini" key="m3" title="Редактирование данных" visible={props.state.modal === 'profile_upd'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title title-big">Редактирование данных</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'id' in props.state.da ? (
                                <Form name="form" form={form2} onFinish={profileupd} className="form" layout="vertical">
                                    { form2.setFieldsValue({ 
                                        lastname:props.state.da.lastname,
                                        firstname:props.state.da.firstname,
                                        patronymic:props.state.da.patronymic,
                                        sex:props.state.da.sex,
                                        phone:props.state.da.phone,
                                        email:props.state.da.email,
                                        address:props.state.da.address
                                    }) }
                                    {
                                        Number(props.state.da.birthday) > 0 &&
                                        form2.setFieldsValue({ 
                                            birthday:moment(fulltime(props.state.da.birthday), "DD.MM.YYYY")
                                        })
                                    }
                                    <Form.Item name="lastname" label="Фамилия" rules={[{ required: true, message: 'Пожалуйста, укажите фамилию!' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="firstname" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя!' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="patronymic" label="Отчество" >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="sex" label="Пол" rules={[{ required: true, message: 'Пожалуйста, укажите пол клиента!' }]}>
                                        <Select>
                                            <Option value="0">Неизвестно</Option>
                                            <Option value="1">Мужской</Option>
                                            <Option value="2">Женский</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="birthday" label="Дата рождения" >
                                        <DatePicker className="datepickerfull" format="DD.MM.YYYY" />
                                    </Form.Item>
                                    <Form.Item name="phone" label="Номер" rules={[{ required: true, message: 'Пожалуйста, укажите номер телефона клиента!' }]}>
                                        <MaskedInput
                                            prefix="+"
                                            mask="7 111-111-11-11"
                                            placeholder="7 999-999-99-99"
                                            size="11"
                                            formatCharacters={{
                                            'W': {
                                                validate(char) { return /\w/.test(char ) },
                                                transform(char) { return char.toUpperCase() }
                                            }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item name="email" label="E-mail" >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="address" label="Адрес" >
                                        <Input />
                                    </Form.Item>
                                    <Divider />
                                    <div className="fcenter">
                                        <Form.Item>
                                            <Button className="button_normal button_blue" shape="round" htmlType="submit">Сохранить</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            ) : (
                                <Empty description="Информация о учетной записи недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <PullToRefresh
                pullDownContent={<div className='container-prt'><FiChevronsDown className='downinfiniti' /></div>}
                releaseContent={<div className='container-prt'><FiChevronsUp className='upinfiniti' /></div>}
                refreshContent={<div className="container-prt"><FiRotateCw className='spininfiniti' /></div>}
                pullDownThreshold={50}
                onRefresh={onRefresh}
                triggerHeight={props.state.triggerHeightScroll}
                startInvisible={true}
            >
                <Row style={{justifyContent:'center'}}>
                    <Col flex="0 0 700px" className="shadowscreen profile_full" >
                        <Result
                            icon={<div className="profile_icon"><Icon component={() => <SVG src={props.state.da.sex === '2' ? girl : man} style={{width:'100%',height:'100%'}} />} /></div>}
                            title={<div className="profile_title">{hello()}<br />{props.state.da.firstname}{props.state.da.patronymic !== '' ? ` ${props.state.da.patronymic}`:``}!</div>}
                            //subTitle={<div className="profile_subtitle">В данном разделе можно увидеть основную информацию о учётной записи и поменять пароль.</div>}
                        />
                        <div className="coldesc">
                            <Paragraph>
                                <Text
                                    strong
                                    style={{
                                        fontSize: 16,
                                    }}
                                >
                                    Основная информация:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Row gutter={16} style={{marginBottom:10,display:'none'}} >
                                    <Col className="gutter-row" span={8}>
                                        Бонусы:
                                    </Col>
                                    <Col className="gutter-row" span={16}>
                                        <Text>{props.state.da.cashback}</Text>
                                    </Col>
                                </Row>
                                <Row gutter={16} style={{marginBottom:10}} >
                                    <Col className="gutter-row" span={8}>
                                        Телефон:
                                    </Col>
                                    <Col className="gutter-row" span={16}>
                                        {props.state.da.phone.length > 0 ? <Text>+{props.state.da.phone}</Text> : <Text type="secondary">не указано</Text>}
                                    </Col>
                                </Row>
                                <Row gutter={16} style={{marginBottom:10}} >
                                    <Col className="gutter-row" span={8}>
                                        Почта:
                                    </Col>
                                    <Col className="gutter-row" span={16}>
                                        {props.state.da.email.length > 0 ? <Text>{props.state.da.email}</Text> : <Text type="secondary">не указано</Text>}
                                    </Col>
                                </Row>
                            </Paragraph>
                            <Divider />
                            <Paragraph>
                                <Text
                                    strong
                                    style={{
                                        fontSize: 16,
                                    }}
                                >
                                    Управление:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Space wrap>
                                    <div className="button_normal button_blue" onClick={() => props.modal('profile_pas')}>Сменить пароль</div>
                                    <div className="button_normal button_blue" onClick={() => props.modal('profile_upd')}>Редактировать данные</div>
                                    <Popconfirm placement="top" title='Вы действительно хотите удалить профиль?' onConfirm={() => profiledel()} okText="Да" cancelText="Нет">
                                        <div className="button_normal button_red" >Удалить профиль</div>
                                    </Popconfirm>
                                </Space>
                            </Paragraph>
                        </div>
                    </Col>
                </Row>    
            </PullToRefresh>
        </React.Fragment>   
    )
};

export default Global;
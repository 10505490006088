import React from 'react';
import { Form, Modal, Row, Col, Switch, Rate, Divider, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { FaUserSecret } from "react-icons/fa";
import { TiStarFullOutline } from "react-icons/ti";

const Global = props => {

    const [counthide, setCounthide] = React.useState(0);
    const [divMouseDown, setDivMouseDown] = React.useState(false);

    const [debugform] = Form.useForm();

    async function platform_send(rate) {
        rate = Math.round(rate);
        rate = rate > 5 ? 5 : rate < 1 ? 1 : rate;
        if (props.state.modal === 'platform_android') {
            await props.api({method:'platform_send',rate:rate,type:'android'},true);
            window.open('https://play.google.com/store/apps/details?id=com.vetchester', '_blank');
        }
        if (props.state.modal === 'platform_ios') {
            await props.api({method:'platform_send',rate:rate,type:'ios'},true);
            window.open('https://itunes.apple.com/app/id1609393573', '_blank');
        }
        props.setState( { modal:'' } );
    }

    const showDrawer = () => {
        props.setState( {leftmenu: 1} );
    };
    
    async function clicklogo() {
        if (counthide < 4) {
            if (divMouseDown) {
                setCounthide(counthide+1);
            } else {
                setDivMouseDown(setTimeout(function() {
                    setDivMouseDown(false);
                }, 3000));
                setCounthide(1);
            }
        } else {
            if (divMouseDown) {
                setCounthide(0);
                props.modal('hidemodal');
            } else {
                setDivMouseDown(setTimeout(function() {
                    setDivMouseDown(false);
                }, 3000));
                setCounthide(1);
            }
        }
    }

    async function onDebug(checked) {
        props.debug(checked);
    }

    return (
        <React.Fragment>
            <Modal mask={false} className="modal-mini bg_chester" key="m0" title="Оплата" visible={props.state.modal === 'hidemodal'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-body">
                    {!props.state.moreload ?
                        <div>
                            <div className="fcenter" style={{marginBottom:'10px'}}>
                                <FaUserSecret style={{fontSize:'100px',color:'#9FC7DE'}} />
                            </div>
                            <Form name="form" form={debugform} className="form" layout="vertical">
                                { debugform.setFieldsValue({ 
                                    debug:props.state.debug
                                }) }
                                <Row gutter={16} style={{marginBottom:10}} >
                                    <Col className="gutter-row" span={12}>
                                        Режим отладки:
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item name='debug' label={null}>
                                            <Switch onChange={onDebug} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className='fcenter hidetext'>
                                    Версия: {props.state.version[0]}<br />
                                    Сборка: {props.state.version[1]}<br />
                                    Дата: {props.state.version[2]}
                                </div>
                            </Form>
                        </div>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal mask={false} className="modal-mini bg_chester" key="m0" title="Оплата" visible={props.state.modal === 'platform_android'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-body">
                    <div>
                        <div className="fcenter" style={{marginBottom:'10px'}}>
                            <TiStarFullOutline style={{fontSize:'100px',color:'#9FC7DE'}} />
                        </div>
                        <div className="fcenter" style={{marginBottom:'10px',display:'flex',flexDirection:'column'}}>
                            <p className='platform_rate_title'>Нравится приложение «Честер»?</p>
                            <p className='platform_rate_text'>Коснитесь, чтобы поставить оценку</p>
                            <Rate onChange={(e) => platform_send(e)} />
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal mask={false} className="modal-mini bg_chester" key="m0" title="Оплата" visible={props.state.modal === 'platform_ios'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-body">
                    <div>
                        <div className="fcenter" style={{marginBottom:'10px'}}>
                            <TiStarFullOutline style={{fontSize:'100px',color:'#9FC7DE'}} />
                        </div>
                        <div className="fcenter" style={{marginBottom:'10px',display:'flex',flexDirection:'column'}}>
                            <p className='platform_rate_title'>Нравится приложение «Честер»?</p>
                            <p className='platform_rate_text'>Коснитесь, чтобы поставить оценку</p>
                            <Rate onChange={(e) => platform_send(e)} />
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="headerfull">
                <div className="logoheader" onClick={() => clicklogo()}>
                    <div className="menu_float">
                        <span onClick={showDrawer} className="menubutton">
                            <MenuOutlined />
                        </span>
                    </div>
                    <div className="logo" />
                </div>
            </div>
            <div className="header_margin"></div>
        </React.Fragment>
    )
};

export default Global;
import React from 'react';
import { Layout } from 'antd';
const Global = props => {
    return (
        <Layout className="loadpage" style={{ minHeight: "100vh" }}>
            <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
        </Layout>
    )
};

export default Global;
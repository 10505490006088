import React, { useState } from 'react';
import { Row, Col, Modal, Empty } from 'antd';
import { FiRotateCw, FiChevronsUp, FiChevronsDown } from "react-icons/fi";
import {PullToRefresh} from "react-js-pull-to-refresh";


const Global = props => {

    const [offer_info, setoffer_info] = useState({});

    async function open_offers(item) {
        props.setState({moreload:true});
        await setoffer_info(item);
        await props.modal('offer_info');
        await props.setState({moreload:false});
    }

    function createMarkup(text) {
        return {__html: text};
    }

    async function start_reload(type) {
        await props.api({ 'method':'offers' },false);
        return true;
    }

    function onRefresh() {
        return start_reload();
    }

    return (
        <React.Fragment>
            <Modal mask={false} width={1000} className="modal-mini" key="m1" title='Предложение' visible={props.state.modal === 'offer_info'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            <div className='offer_title'>{'title' in offer_info ? offer_info['title'] : 'Заголовок'}</div>
                            <div className='offer_content' dangerouslySetInnerHTML={createMarkup(offer_info['text'])} />
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <PullToRefresh
                pullDownContent={<div className='container-prt'><FiChevronsDown className='downinfiniti' /></div>}
                releaseContent={<div className='container-prt'><FiChevronsUp className='upinfiniti' /></div>}
                refreshContent={<div className="container-prt"><FiRotateCw className='spininfiniti' /></div>}
                pullDownThreshold={50}
                onRefresh={onRefresh}
                triggerHeight={props.state.triggerHeightScroll}
                startInvisible={true}
            >
                <Row className="wowload">
                    <Col key="k2_clients" span={24} className="padding_panel tooglecolor" >
                        { props.state.offers.length > 0 ?
                            <div className='offers_list'>
                                {props.state.offers.map( item => 
                                    <div className='offers_item' onClick={() => open_offers(item)}>
                                        <div 
                                            className='offers_block'
                                            key={item.id}
                                        >
                                            <img className='offers_photo' alt={item.title} src={item.photo} />
                                            <div class="card-content">
                                                <h2>{item.title}</h2>
                                                <span>{item.price}</span>
                                                <p>{`Услуга: ${item.subtitle}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        :
                            <div className="fcenter"><Empty /></div>
                        }
                    </Col>
                </Row>
            </PullToRefresh>
        </React.Fragment>
    )
};

export default Global;
import React from 'react';
import { hot } from 'react-hot-loader/root';
import sizeMe from 'react-sizeme';
import eruda 	from 'eruda';

import 'moment/locale/ru';
import locale from 'antd/lib/locale/ru_RU';
import 'antd/dist/antd.css';
import './App.css';

import { Layout, ConfigProvider, Tooltip, notification, Modal, List } from 'antd';
import { SettingOutlined, AppstoreOutlined, BarsOutlined, ImportOutlined, TeamOutlined, PlusOutlined, UserAddOutlined, FormOutlined, BarChartOutlined, SendOutlined, SolutionOutlined, HeartOutlined, LikeOutlined, PhoneOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { MdPets } from "react-icons/md";
import { FaBookMedical, FaPercentage } from "react-icons/fa";
import { IoCalendarOutline } from "react-icons/io5";
import { BsFillPeopleFill } from "react-icons/bs";

//@ Служебные
import Auth 		                        from './components/Auth/Auth';
import GlobLoadpage 		      from './components/Global/Loadpage';
import GlobHeader 		           from './components/Global/Header';
import GlobMenuF 		           from './components/Global/Leftmenufloat';
import GlobMenu 		           from './components/Global/Leftmenu';
import Profile 		                           from './components/Panels/Profile';
//import Dev 		                           from './components/Panels/Dev';
import Consult 		                           from './components/Panels/Consult';
import RecordLocal 		                           from './components/Panels/RecordLocal';
import Calendar 		                           from './components/Panels/Calendar';
import Cashback 		                           from './components/Panels/Cashback';
import Offers 		                           from './components/Panels/Offers';
import Doctors 		                           from './components/Panels/Doctors';

//@ Панели
import Services 		                  from './components/Panels/Services';
import Clients 		                  from './components/Panels/Clients';

const { Content } = Layout;

const notif = (type,title, text) => {
  notification[type]({
    message: title,
    description:text,
    placement:'bottomRight',
  });
};

var userDeviceArray = [
  {device: 'Android', platform: /Android/},
  {device: 'iPhone', platform: /iPhone/},
  {device: 'iPad', platform: /iPad/},
  {device: 'Symbian', platform: /Symbian/},
  {device: 'Windows Phone', platform: /Windows Phone/},
  {device: 'Tablet OS', platform: /Tablet OS/},
  {device: 'Linux', platform: /Linux/},
  {device: 'Windows', platform: /Windows NT/},
  {device: 'Macintosh', platform: /Macintosh/}
];

var platform = navigator.userAgent;

class App extends React.Component {
  constructor(props) {
		super(props);

    this.state = {
      auth: 0,
      error: ['',''],
      da: [],
      loading: true,
      moreload: true,
      modal: '',
      panel: [],
      active: 0,
      menu: [],
      api: 'https://clinic.rus2.ru/api/2_l/',
      balance: 0,
      success: false,
      calendar: [],
      consult: [],
      emp: [],
      search: '',
      width: 0,
      users: [],
      leftmenu: 0,
      patients: [],
      patientst: [],
      patientsb: [],
      patientinfo: [],
      services: [],
      pattitem: [],
      recordinfo: [],
      paytype: [],
      consultinfo: [],
      consultations: [],
      cashback: [],
      events: [],
      tempauth: ['','',''],
      recordslocal: [],
      recordstype: [],
      cashback_faq: [],
      offers: [],
      doctors: [],
      constype: 1,
      consactive: [],
      eventicons: [],
      linktopay: '',
      fullsize: {width:'2048px',height:'800px'},
      scroll: 0,
      triggerHeightScroll: '100%',
      debug: false,
      version: [
        '1.8',
        '89',
        '28/07/23'
      ],
    };
    this.onChange 	    = this.onChange.bind(this);
  }

  //* Вызов сразу после рендера
  async componentDidMount() {
    if (this.getCookie('token') !== undefined) {
      await this.login(1,1,this.getCookie('token'));
    } else {
      await this.setState( { auth:1 } );
    }
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('scroll', this.updateScroll);
    this.setState({fullsize:{width:window.innerWidth,height:window.innerHeight}});
    //this.setState({scroll:window.innerHeight});
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('scroll', this.updateScroll);
  }

  updateDimensions = (event) => {
    this.setState({fullsize:{width:event.currentTarget.innerWidth,height:event.currentTarget.innerHeight}});
    this.setState({scroll:window.innerHeight});
    this.setState({ leftmenu:0 })
  };

  updateScroll = () => {
    if (window.pageYOffset) {
      if (this.state.triggerHeightScroll) {
        this.setState({triggerHeightScroll:0});
      }
    } else {
      if (!this.state.triggerHeightScroll) {
        this.setState({triggerHeightScroll:'100%'});
      }
    }
    console.log(window.pageYOffset,this.state.triggerHeightScroll);
  };

  debug = ( a ) => {
		if (a) {
			let el = document.createElement('div');
			document.body.appendChild(el);

			this.setState( { debug:true } );
			eruda.init({
				container: el,
				tool: ['console'],
				useShadowDom: true,
				autoScale: true
			});
		} else {
			this.setState( { debug:false } );
			eruda.destroy();
		}
	}

  getPlatform() {
    for (var i in userDeviceArray) {
        if (userDeviceArray[i].platform.test(platform)) {
            return userDeviceArray[i].device;
        }
    }
    return platform;
  }


  getPlatformUrl(rate_android, rate_ios) {
    let p = this.getPlatform();
    switch(p) {
      case 'Android':
        if (+rate_android === 0) {
          this.modal('platform_android');
        }
        break;
      case 'iPhone':
      case 'iPad':
        if (+rate_ios === 0) {
          this.modal('platform_ios');
        }
        break;
    }
  }

  //* Текущее время 
  totime() {
    let date = new Date();
    let d1 = (date.getDate()<10?'0':'') + date.getDate();
    let d2 = date.getMonth()+1;
    d2 = (d2<10?'0':'') + d2;
    let d3 = date.getFullYear();
    let d4 = (date.getHours()<10?'0':'') + date.getHours();
    let d5 = (date.getMinutes()<10?'0':'') + date.getMinutes();
    return `${d1}.${d2}.${d3} - ${d4}-${d5}`;
  }

  //* POST запрос
  postRequest(url, data) {
    return fetch(url, {
      credentials: 'same-origin',
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
    })
    .then(response => response.json())
  }

  //* Вход
  async login (l,p,t) {
    await this.setState( { auth: 0 } );
    let user = { phone: l, pass: p, method: 'auth' };
    if (t) {
      user = { token: t, method: 'auth' };
    }
    
    await this.postRequest(`${this.state.api}`, user)
    .then(async json => {
      var data = json;
      if(data.code) {
        let da          = ( data.da ) ? data.da : {};
        let menu          = ( data.menu ) ? data.menu : [];
        let balance = ( data.balance ) ? data.balance : 0; 
        let emp = ( data.emp ) ? data.emp : [];
        let cashback_faq          = ( data.cashback_faq ) ? data.cashback_faq : [];
        
        await this.setState( {
          da: da,
          menu: menu,
          balance: balance,
          emp: emp,
          cashback_faq: cashback_faq
        } );
  
        if (!t) {
          this.setCookie('token', data.da.token);
        }

        if (menu.length > 0) {
          await this.menugo(menu[0].id);
        }

        await this.setState( {
          auth:2,
          loading: false
        } );
      }
      if(data.error) {
        await this.setState( { auth:1, error: data.error } );
        notif('error',`${data.error[0]}`,`${data.error[1].length > 0 ? `${data.error[1]}`:''}`);
        if (data.error[0] === 'Неверный логин или пароль') {
          await this.delCookie('token');
          await this.setState( {auth: 1, error: ['','']} );
        }
      }
    })
    .catch( async err => {
      await this.setState( { auth:1, error: ['Проблемы со связью',''] } );	//Проблемы со связью
    });
  }

  //* Текст поиска
  onChange (e) { 
    this.setState({ search:e.target.value.replace(/\s+/g,' ') }); 
  }

  //* Получение информации
  async api(v,s) {
    if (s) {
      await this.setState( { loading: true, moreload:true, modal:'' } );
    } else {
      await this.setState({ moreload:true });
    }
    
    v['token'] = this.getCookie('token');
    await this.postRequest(`${this.state.api}`, v)
    .then(async json => {
      var data = json;
      if(data.code) {
        let da          = ( data.da ) ? data.da : {};
        let menu          = ( data.menu ) ? data.menu : [];
        let cashback_faq          = ( data.cashback_faq ) ? data.cashback_faq : [];

        await this.setState( {
          da: da,
          menu: menu,
          cashback_faq: cashback_faq
        } );

        //* Методы
        switch(v['method']) {
          
          //@ Профиль
          case 'profile':
            setTimeout(() => this.getPlatformUrl(da.rate_android,da.rate_ios), 2000);
            break;

          //@ Регистрация
          case 'registration':
              var tempauth = this.state.tempauth;
              notif('success','Вы успешно зарегистрировались!','Теперь вы можете войти в свою учетную запись.');
              await this.login(tempauth[0],tempauth[1]);
              break;

          //@ Пациенты
          case 'patients':
            var patientst = ( data.patientst ) ? data.patientst : [];
            var patients 		= ( data.patients ) ? data.patients : [];
            var patientsb     = ( data.patientsb ) ? data.patientsb : [];
            await this.setState( { patients:patients, patientst:patientst, patientsb:patientsb } );
            break;

          //@ Клиенты
          case 'patient_get':
            var patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
            patientsb     = ( data.patientsb ) ? data.patientsb : [];
            await this.setState( { patientinfo:patientinfo, patientsb:patientsb } );
            break;
          case 'patient_add':
            patientst = ( data.patientst ) ? data.patientst : [];
            patientsb     = ( data.patientsb ) ? data.patientsb : [];
            patients 		= ( data.patients ) ? data.patients : [];
            await this.setState( { patients:patients, patientst:patientst, patientsb:patientsb } );
            notif('success','Пациент добавлен!','');
            break;
          case 'patient_upd':
            patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
            patientsb     = ( data.patientsb ) ? data.patientsb : [];
            patientst = ( data.patientst ) ? data.patientst : [];
            patients 		= ( data.patients ) ? data.patients : [];
            await this.setState( { patients:patients, patientst:patientst, patientinfo:patientinfo, patientsb:patientsb } );
            notif('success','Пациент обновлён!','');
            break;
          case 'patient_prop':
            patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
            await this.setState( { patientinfo:patientinfo } );
            notif('success','Параметры пациента обновлены!','');
            break;
          case 'patient_block':
            patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
            await this.setState( { patientinfo:patientinfo } );
            notif('success','Статус пациента обновлён!','');
            break;

          //@ Клиенты
          case 'client_upd':
            notif('success','Профиль обновлён!','');
            break;
          //@ Пароль
          case 'client_pas':
            var modal 		= ( data.modal ) ? data.modal : [];
            this.modalresp(modal);
            notif('success','Ваш пароль обновлён','');
            break;

          //@ Приёмы
          case 'record_get':
            var recordinfo 		= ( data.recordinfo ) ? data.recordinfo : [];
            patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
            await this.setState( { patientinfo:patientinfo, recordinfo:recordinfo } );
            break;

          //@ Услуги
          case 'services':
            var services    = ( data.services ) ? data.services : [];
            patientst    = ( data.patientst ) ? data.patientst : [];
            await this.setState( {
              services: services, patientst: patientst
            } );
            break;

          //@ Календарь
          case 'calendar':
            var calendar 		= ( data.calendar ) ? data.calendar : [];
            await this.setState( { calendar:calendar } );
            break;

          //@ Консультации
          case 'consult':
            var consultations 		= ( data.consultations ) ? data.consultations : [];
            var paytype 		= ( data.paytype ) ? data.paytype : [];
            var consult 		= ( data.consult ) ? data.consult : [];
            patients 		= ( data.patients ) ? data.patients : [];
            await this.setState( { consult:consult, paytype:paytype, consultations:consultations, patients:patients } );
            break;

          //@ Консультации
          case 'consult_active':
            consultations 		= ( data.consultations ) ? data.consultations : [];
            paytype 		= ( data.paytype ) ? data.paytype : [];
            consult 		= ( data.consult ) ? data.consult : [];
            patients 		= ( data.patients ) ? data.patients : [];
            await this.setState( { consult:consult, paytype:paytype, consultations:consultations, patients:patients } );
            break;

          //@ Cashback
          case 'cashback':
            var cashback 		= ( data.cashback ) ? data.cashback : [];
            await this.setState( { cashback:cashback } );
            break;
        
          //@ Offers
          case 'offers':
            var offers 		= ( data.offers ) ? data.offers : [];
            await this.setState( { offers:offers } );
            break;

          //@ Doctors
          case 'doctors':
            var doctors 		= ( data.doctors ) ? data.doctors : [];
            await this.setState( { doctors:doctors } );
            break;

          //@ Новая заявка
          case 'consult_add':
            consult 		= ( data.consult ) ? data.consult : [];
            var returnid = ( data.returnid ) ? data.returnid : 0;
            await this.setState( { consult:consult } );
            notif('success','Заявка создана, ожидается оплата.','');
            if (returnid > 0) {
              await this.api({
                'method':'consult_get',
                'payid':returnid
              },false);
            }
            this.modal('consult_paylink');

            var t = {};
            t['payid'] = returnid;
            t['method'] = 'consult_pay';
            await this.api(t,false);
            break;

          //@ Новая заявка
          case 'consult_get':
            var consultinfo 		= ( data.consultinfo ) ? data.consultinfo : [];
            paytype 		= ( data.paytype ) ? data.paytype : [];
            await this.setState( { consultinfo:consultinfo, paytype:paytype } );
            break;

          //@ Выбор оплаты
          case 'consult_pay':
            var returnurl = ( data.returnurl ) ? data.returnurl : '/';
            await this.setState( { linktopay:returnurl } );
            break;

          //@ Консультации
          case 'recordstype':
            var recordstype 		= ( data.recordstype ) ? data.recordstype : [];
            await this.setState( { recordstype:recordstype } );
            break;

          //@ Консультации
          case 'recordlocal_add':
            notif('success','Заявка отправлена','');
            break;

          //@ Восстановление пароля
          case 'newpass':
            notif('success','Запрос на смену пароля отправлен! Ожидайте SMS.','');
            break;

          default: break;
        }
      }
      if(data.error) {
        await this.setState( { error: data.error } );
        notif('error',`${data.error[0]}`,`${data.error[1].length > 0 ? `${data.error[1]}`:''}`);
        if (data.error[0] === 'Неверный логин или пароль') {
          await this.delCookie('token');
          await this.setState( {auth: 1, error: ['','']} );
        }
      }
    })
    .catch( async err => {
      await this.setState( { error: ['Проблемы со связью',''] } );	//Проблемы со связью
    });
    if (s) {
      await this.setState( { loading: false, moreload: false } );
    } else {
      await this.setState({ moreload:false });
    }
  }

  async timesuccess () {
    await this.setState( { success:true } );
    await setTimeout(() => this.setState( { success:false } ), 2 * 1000);
  };

  //* Получение нужного куки
  getCookie(name) {
    let matches = document.cookie.match(new RegExp( "(?:^|; )" + name.replace(/([$?*|{}\]\\^])/g, '\\$1') + "=([^;]*)" ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  //* Линк на пункт меню
  menulink = (e) => { let id = e.currentTarget.dataset.id; this.menugo(id); }
  
  //* Обнуление Tab разделов
  async deltabs() {

  }

  //* Модальное окно после запроса
  async modalresp(resp) {
    if ('title' in resp && 'data' in resp) {
      if (resp.data instanceof Array) {
        await Modal.info({
          title: resp.title,
          content: (
            <List
                pagination={{ pageSize: 10 }}
                itemLayout="horizontal"
                dataSource={resp.data}
                renderItem={itemlist => (
                <List.Item>
                    <List.Item.Meta
                    title={itemlist['name']}
                    description={itemlist['text']}
                    />
                </List.Item>
                )}
            />
          ),
          okType: 'dashed',
          okText: 'Продолжить',
          onOk() {},
        });   
      }
    }
  }

  //* Переход на другой раздел
	async menugo(a) {
    await this.setState( { loading: true, leftmenu:0 } );
    await this.deltabs();
    if (a < 1) {
      await this.setState( { active:a } );
      switch(a) {
        case '0':
          await this.delCookie('token');
          await this.setState( {auth: 1, error: ['','']} );
          await notif('success','Вы успешно вышли!','Пожалуйста, подождите пару секунд');
          await window.location.reload();
          break;
        case '-1':
          await this.api({ 'method':'auth' },true);
          break;
        default:  break;
      }
      await this.setState( { loading: false } );
    } else {
      switch(a) {
         //* Данные
         case '1':
          await this.api({ 'method':'profile' },true);
          break;
         //* Пациенты
        case '2':
          await this.api({ 'method':'patients' },true);
          break;
        //* Услуги
        case '3':
          await this.api({ 'method':'services' },true);
          break;
        //* Календарь
        case '4':
          await this.api({ 'method':'calendar' },true);
          break;
        //* Консультации
        case '5':
          await this.api({ 'method':'consult_active' },true);
          break;
        //* Cashback
        case '6':
          await this.api({ 'method':'cashback' },true);
          break;
        //* Запись
        case '7':
          await this.api({ 'method':'patients' },true);
          //await this.api({ 'method':'recordstype' },true);
          break;
        //* Предложения
        case '8':
          await this.api({ 'method':'offers' },true);
          break;
         //* Предложения
        case '9':
          await this.api({ 'method':'doctors' },true);
          break;
        //* Данные
        default:
          await this.api({ 'method':'profile' },true);
          break;
      }
      await this.setState( { active:a } );
      await this.setState( { loading: false } );
    }
  }

  icons(n) {
    switch(n) {
      case 'tasks': return <BarsOutlined />;
      case 'clientadd': return <UserAddOutlined />;
      case 'sms': return <SendOutlined />;
      case 'clients': return <TeamOutlined />;
      case 'feedback': return <LikeOutlined />;
      case 'stats': return <BarChartOutlined />;
      case 'settings': return <SettingOutlined />;
      case 'services': return <FaBookMedical />;
      case 'rec': return <LikeOutlined />;
      case 'patientst': return <HeartOutlined />;
      case 'script': return <FormOutlined />;
      case 'profile': return <SolutionOutlined />;
      case 'calendar': return <IoCalendarOutline />;
      case 'consult': return <PhoneOutlined />;
      case 'patients': return <MdPets />;
      case 'cashback': return <DollarCircleOutlined />;
      case 'offers': return <FaPercentage />;
      case 'recordlocal': return <PlusOutlined />;
      case 'doctors': return <BsFillPeopleFill />;
      case '0': return <ImportOutlined />;
      default: return <AppstoreOutlined />;
    }
  }

  //* Добавление куки
  setCookie(name, value, options = {}) {
    options = { path: '/', ...options };
    if (options.expires instanceof Date) { options.expires = options.expires.toUTCString(); }
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) { updatedCookie += "=" + optionValue; }
    }
    document.cookie = updatedCookie;
  }

  //* Сортировка
  htc = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  //* Удаление куки
  delCookie (name) { this.setCookie(name, "", { 'max-age': -1 }) };

  //* Случайный цвет
  randcolor() {
    var arr = ['#cc0029', '#E14015', '#f56a00', '#FA9500', '#ffbf00', '#9EB51E', '#3caa3c', '#1EA675', '#00a2ae', '#42aaff', '#2E82E8', '#1959d1', '#7265e6'];
    var rand = Math.floor(Math.random() * arr.length);
    return arr[rand];
  }

  //* Модальные окна
  async modal(t) {
    await this.setState( { modal:t } );
  }

  //* Модальные окна
  async panel(t) {
    var panels = this.state.panel;
    if (panels.indexOf( t ) != -1) {
      var index = panels.indexOf(t);
      if (index > -1) panels.splice(index, 1);
    } else {
      await panels.push(t);
    }
    await this.setState( { panel:panels } );
  }

  decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }

  //* Полная дата
  fulltime(date,time ) {
    if (time === undefined) {time = '00:00';}
    var ot = new Date(date);
    var nt = new Date('1970-01-01T' + time + ':00');
    ot.setHours(nt.getHours(),nt.getMinutes());
    return ot;
  }

  //* Дата
  lasttime(time,empty,type) {
    let newtime 	= new Date();
		let oldtime 	= new Date();
		let notiftime 	= new Date( time * 1000 );
		newtime.setMinutes( newtime.getMinutes() );
		oldtime.setMinutes( newtime.getMinutes() - 1440 );
		notiftime.setMinutes( notiftime.getMinutes());
		let newdate 	= Math.floor( newtime.getTime()/1000 );
		let notifdate 	= Math.floor( notiftime.getTime()/1000 );
		let diff 		= newdate-notifdate;
		let monthlist 	= { "00":"янв ", "01":"фев ", "02":"мар ", "03":"апр ", "04":"мая ", "05":"июн ", "06":"июл ", "07":"авг ", "08":"сен ", "09":"окт ", "10":"ноя ", "11":"дек " };
		let info 		= '';
		if ( diff < 60 ) 	{ info = Math.floor( diff ) + this.decnum( Math.floor( diff ),[ ' секунду ',' секунды ',' секунд ' ] ) + " назад "; }
		if ( diff > 60 ) 	{ info = Math.floor( diff/60 ) + this.decnum( Math.floor( diff/60 ),[ ' минуту ',' минуты ',' минут '] ) + " назад "; }
		if ( diff > 1800 ) 	{ info = "полчаса назад "; }
		if ( diff > 3600 ) 	{ info = "час назад "; }
		if ( diff > 7200 ) 	{ info = "2 часа назад "; }
		if ( diff > 10800 ) { info = "3 часа назад "; }
		if ( diff > 14400 ) {
			if ( ( '0' + notiftime.getDate() ).slice( -2 ) === ( '0' + newtime.getDate() ).slice( -2 ) ) {
				info = "сегодня в " + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + " "; 
			} else {
				info = "вчера в " + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + " ";
			}
		}
		if ( diff > 24*3600 ) {
			if ( ('0' + notiftime.getDate()).slice(-2) === ('0' + oldtime.getDate()).slice(-2) ) {
				info = "вчера в " + ('0' + notiftime.getHours()).slice(-2) + ':' + ('0' + notiftime.getMinutes()).slice(-2) + " "; 
			} else {
				info = ('0' + notiftime.getDate()).slice(-2) + ' ' + monthlist[('0' + notiftime.getMonth()).slice(-2)] + ' в ' + ('0' + notiftime.getHours()).slice(-2) + ':' + ('0' + notiftime.getMinutes()).slice(-2) + ' ';
			}
		}
		if ( diff > 2*24*3600 ) { info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года в ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' '; }
		let diffrev = notifdate-newdate;
		if ( diff === 0 ) 	{ info = "только что "; }
		if (diffrev > 0) { info = info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года в ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' '; }
		let full = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года в ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' ';
		switch (type) {
      case 'last':
        info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года ';
        break;
      case 'full':
        info = full;
        break;
      default: break;
    }
    if (time === '0') {
      info = full = empty;
    }
    return( <Tooltip title={full}>{info}</Tooltip> );
  }

  //* Дата
  lasttimee(time,empty) {
		let notiftime 	= new Date( time * 1000 );
		notiftime.setMinutes( notiftime.getMinutes());
		let full = ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' ';
    return( <Tooltip title={full}>{full}</Tooltip> );
  }

  //* Рендер
  render() {
    const spin = <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>;
    const f_Login = (v) => { this.login(Number((v['phone']).replace(/\D+/g,"")),v['password']); };
    //const { width } = this.props.size;

    return (
      <ConfigProvider locale={locale}>
        {this.state.auth === 2 &&
          <GlobMenuF state={this.state} setState={this.setState.bind(this)} menugo={this.menugo.bind(this)} icons={this.icons} />
        }
        <Layout style={{ minHeight: "100vh" }}>
          {this.state.auth === 0 && <GlobLoadpage />}
          {this.state.auth === 1 && <Auth f_Login={f_Login} state={this.state} setState={this.setState.bind(this)} modal={this.modal} api={this.api.bind(this)} />}
          {this.state.auth === 2 &&
            <Layout className="lglobal">
              {this.state.auth === 2 &&
                <GlobMenu className="menu_pc" state={this.state} setState={this.setState.bind(this)} menugo={this.menugo.bind(this)} icons={this.icons} />
              }
              <Content className='global_app_content'>
                <GlobHeader state={this.state} setState={this.setState.bind(this)} modal={this.modal} api={this.api.bind(this)} menugo={this.menugo.bind(this)} debug={this.debug.bind(this)} />
                {!this.state.loading ?
                  <div className="site-layout-background" style={{ padding: 24 }}>
                    {this.state.active === "1" &&
                      <Profile        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} menugo={this.menugo.bind(this)} lasttime={this.lasttime.bind(this)} htc={this.htc} onChange={this.onChange} width={this.state.fullsize.width} />
                    }
                    {this.state.active === "2" &&
                      <Clients        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} htc={this.htc} onChange={this.onChange} width={this.state.fullsize.width} />
                    }
                    {this.state.active === "3" &&
                      <Services        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} htc={this.htc} onChange={this.onChange} width={this.state.fullsize.width} />
                    }
                    {this.state.active === "4" &&
                      <Calendar        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} htc={this.htc} onChange={this.onChange} width={this.state.fullsize.width} decnum={this.decnum} />
                    }
                    {this.state.active === "5" &&
                      <Consult        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} htc={this.htc} onChange={this.onChange} width={this.state.fullsize.width} lasttimee={this.lasttimee.bind(this)} />
                    }
                    {this.state.active === "6" &&
                      <Cashback        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} htc={this.htc} onChange={this.onChange} width={this.state.fullsize.width} decnum={this.decnum} />
                    }
                    {this.state.active === "7" &&
                      <RecordLocal        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} htc={this.htc} onChange={this.onChange} width={this.state.fullsize.width} lasttimee={this.lasttimee.bind(this)} fulltime={this.fulltime.bind(this)} />
                    }
                    {this.state.active === "8" &&
                      <Offers        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} htc={this.htc} onChange={this.onChange} width={this.state.fullsize.width} lasttimee={this.lasttimee.bind(this)} fulltime={this.fulltime.bind(this)} />
                    }
                    {this.state.active === "9" &&
                      <Doctors        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} htc={this.htc} onChange={this.onChange} width={this.state.fullsize.width} lasttimee={this.lasttimee.bind(this)} fulltime={this.fulltime.bind(this)} />
                    }
                  </div>
                  :
                  spin
                }
              </Content>
            </Layout>
          }
        </Layout>
      </ConfigProvider>
    );
  }
}

export default hot(sizeMe()(App));